<template>
  <div class="sales-order">
    <load-profile></load-profile>
    <b-row>
      <b-col md="7" xl="8">
        <!-- Searchbar -->
        <div class="ecommerce-searchbar mb-1">
          <b-row align-v="center">
            <b-col>
              <search-input
                v-model="params.search"
                @startTyping="productLoading = true"
                @serach="search"
              />
            </b-col>
            <b-col cols="auto" class="pl-0 pr-md-0">
              <product-brand-dropdown-filter @change="onBrandChange" />
            </b-col>
          </b-row>
        </div>

        <b-skeleton-wrapper :loading="productLoading">
          <template #loading>
            <b-row>
              <b-col
                class="pr-md-0"
                md="6"
                lg="4"
                xl="3"
                v-for="i in query.limit"
                :key="`loading-item-${i}`"
              >
                <product-card-loading />
              </b-col>
            </b-row>
          </template>
          <b-row class="product-list-responsive">
            <b-col
              class="pr-md-0"
              md="6"
              lg="4"
              xl="3"
              v-for="(item, idx) in products"
              :key="`item-${item.id}`"
            >
              <product-card
                :item="item"
                :index="idx"
                :cart="cart"
                :cartLines="cartLines"
                @addCart="addCart"
                @removeCart="removeCartFromList"
                @updateQuantity="showUpdateQuantityModal"
              />
            </b-col>
          </b-row>
          <n-pagination
            class="pagination"
            :key="`pagination-${productKey}`"
            ref="pagination"
            :total="productTotal"
            :per-page="query.limit"
            :page="query.page"
            @change="changePage"
          ></n-pagination>
        </b-skeleton-wrapper>
      </b-col>
      <b-col md="5" xl="4">
        <order-info
          :key="`cart-${cartKey}`"
          :isSubmit="isSubmit"
          :checkoutLoading="checkoutLoading"
          :loading="cartLoading"
          :cart="cart"
          :cartLines="cartLines"
          removeItem
          :setOutletLoading="setOutletLoading"
          @changeQuantity="onChangeQuantity"
          @addPromotionFunction="addPromotionFunction"
          @delete="removeCart"
          @clear="clearCart"
          @proceed="proceed"
          @changeOutlet="onChangeOutlet"
          @changeAddress="onChangeAddress"
          @changeSalesperson="onChangeSalesperson"
          @updateExpectedDeliveryDate="showUpdate(ExpectDeliveryDateInput)"
          @updateOrderReference="showUpdate(OrderReferenceInput)"
          @updateRemark="showUpdate(RemarkInput)"
        />
      </b-col>
    </b-row>

    <update-quantity-modal @save="updateQuantity" ref="updateQuantityModal" />
    <update-modal ref="updateModal" @save="onUpdate" />
    <confirm-modal ref="confirmOrderModal" @submit="checkout" />
    <promotion-function-modal
      @save="savePromotionFunction"
      ref="promotionFunctionModal"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BSkeletonWrapper,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import ProductCard from "@/components/dp/ProductCard.vue";
import ProductCardLoading from "@/components/dp/ProductCardLoading.vue";
import NPagination from "@/components/NPagination";
import OrderInfo from "./OrderInfo.vue";
import SearchInput from "@/components/dp/SearchInput.vue";
import ProductBrandDropdownFilter from "@/components/dp/ProductBrandDropdownFilter.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UpdateQuantityModal from "@/components/dp/quantity/UpdateQuantityModal.vue";
import UpdateModal from "@/components/dp/updateModal/Index.vue";
import ConfirmModal from "@/components/dp/confirmModal/Index.vue";
import PromotionFunctionModal from "@/components/dp/promotionFunctionModal/Index.vue";
import Swal from "sweetalert2";

import ExpectDeliveryDateInput from "./form/expectDeliveryDateInput";
import OrderReferenceInput from "./form/orderReferenceInput";
import RemarkInput from "./form/remarkInput";

const ProductRepository = Repository.get("product");
const CartRepository = Repository.get("cart");
const salesOrderType = 1;

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BSkeletonWrapper,

    NPagination,
    ProductCard,
    ProductCardLoading,
    OrderInfo,
    SearchInput,
    ProductBrandDropdownFilter,
    UpdateQuantityModal,
    UpdateModal,
    ConfirmModal,
    PromotionFunctionModal,
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.order;
      },
      set(value) {
        this.$store.commit("column/SET_ORDER_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      cartKey: 1,
      key: 1,
      productKey: 1,
      products: [],
      productTotal: 0,
      productLoading: false,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        search: this.$route.query.search || null,
        productBrandId: this.$route.query.productBrandId || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || "nameEn",
        sort: this.$route.query.sort || "ASC",
        limit: 12,
        search: this.$route.query.search || null,
        productBrandId: this.$route.query.productBrandId || [],
      },
      cartLoading: false,
      cart: {},
      cartLines: [],
      setOutletLoading: false,

      isSubmit: false,

      checkoutLoading: false,

      ExpectDeliveryDateInput,
      OrderReferenceInput,
      RemarkInput,
    };
  },
  mounted() {
    this.listProduct();
    this.showCart();
  },
  methods: {
    onBrandChange(productBrandId) {
      this.params.productBrandId = productBrandId;
      this.search();
    },
    search() {
      this.query.page = 1;
      if (this.$refs.pagination) {
        this.$refs.pagination.reset();
      }
      this.listProduct();
    },
    changePage(page = 1) {
      this.query.page = page;
      this.listProduct();
    },
    listProduct() {
      this.productLoading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      ProductRepository.index({
        ...this.query,
        searchFields: [
          {
            key: "productBrandId",
            operator: "in",
          },
        ],
        query: [
          {
            searchby: "dp_visible",
            searchoperator: "=",
            search: 1,
          },
        ],
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            const item = data.list.map((element) => {
              return {
                ...element,
                loading: false,
              };
            });
            this.products = [...item];
            this.productTotal = data.total;
            this.productKey++;
          }
          this.productLoading = false;
        })
        .catch(() => {
          this.productLoading = false;
        });
    },
    showCart() {
      this.cartLoading = true;
      CartRepository.index({
        type: salesOrderType,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.cartLoading = false;
        })
        .catch(() => {
          this.cartLoading = false;
        });
    },
    addCart(item, index) {
      this.products[index].loading = true;
      CartRepository.addProduct({
        type: salesOrderType,
        productId: item.id,
        quantity: 1,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.products[index].loading = false;
          this.showToast(
            this.$t("cart.addToCart"),
            this.$t("cart.itemAdded", {
              value: this.trans(item, "name", this.$i18n.locale),
            })
          );
        })
        .catch(() => {
          this.products[index].loading = false;
        });
    },
    removeCartFromList(cartLine) {
      this.removeProductFromCart(cartLine);
    },
    removeCart(cartLine) {
      this.removeProductFromCart(cartLine);
    },
    removeProductFromCart(cartLine) {
      this.startProductLoading(cartLine.productId);
      this.startCartLineLoading(cartLine.id);
      CartRepository.removeProduct({
        cartLineId: cartLine.id,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.stopProductLoading(cartLine.productId);
          this.showToast(
            this.$t("cart.removeFromCart"),
            this.$t("cart.itemRemoved", {
              value: this.trans(cartLine, "productName", this.$i18n.locale),
            }),
            "light-danger",
            "danger"
          );
        })
        .catch(() => {
          this.stopProductLoading(cartLine.productId);
          this.stopCartLineLoading(cartLine.id);
        });
    },
    showUpdateQuantityModal(cartLine) {
      this.$refs.updateQuantityModal.show(cartLine);
    },
    onChangeQuantity(item, quantity) {
      item.quantity = quantity;
      this.updateQuantity(item, false);
    },
    updateQuantity(cartLine, isShowMessage = true) {
      CartRepository.updateLineQuantity({
        type: salesOrderType,
        cartLineId: cartLine.id,
        quantity: cartLine.quantity,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.$refs.updateQuantityModal.hide();
          if (isShowMessage) {
            this.showToast(
              this.$t("cart.quantityUpdated"),
              this.$t("cart.itemQuantityUpdated", {
                value: this.trans(cartLine, "productName", this.$i18n.locale),
                quantity: cartLine.quantity,
              })
            );
          }
        })
        .catch(() => {
          this.$refs.updateQuantityModal.stopLoading();
        });
      // CartRepository.addProduct({
      //   type: salesOrderType,
      //   productId: cartLine.productId,
      //   quantity: cartLine.quantity,
      // })
      //   .then((response) => {
      //     const data = response?.data?.data;
      //     this.mapCart(data);
      //     this.$refs.updateQuantityModal.hide();
      //     if (isShowMessage) {
      //       this.showToast(
      //         this.$t("cart.quantityUpdated"),
      //         this.$t("cart.itemQuantityUpdated", {
      //           value: this.trans(cartLine, "productName", this.$i18n.locale),
      //           quantity: cartLine.quantity,
      //         })
      //       );
      //     }
      //   })
      //   .catch(() => {
      //     this.$refs.updateQuantityModal.stopLoading();
      //   });
    },
    onChangeOutlet(outletId) {
      this.setOutletLoading = true;
      CartRepository.setOutlet({
        type: salesOrderType,
        outletId: outletId,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.showToast(
            this.$t("cart.outletChanged"),
            this.$t("cart.outletChangeTo", {
              value: this.trans(this.cart, "outletName", this.$i18n.locale),
            })
          );
          this.setOutletLoading = false;
        })
        .catch(() => {
          this.setOutletLoading = false;
        });
    },
    onChangeAddress(shippingOptionId) {
      this.setOutletLoading = true;
      CartRepository.setAddress({
        type: salesOrderType,
        shippingOptionId: shippingOptionId,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.showToast(
            this.$t("cart.addressChanged"),
            this.$t("cart.addressChangeTo", {
              value: this.trans(this.cart, "outletAddress", this.$i18n.locale),
            })
          );
          this.setOutletLoading = false;
        })
        .catch(() => {
          this.setOutletLoading = false;
        });
    },
    onChangeSalesperson(salespersonId) {
      this.setOutletLoading = true;
      CartRepository.setSalesperson({
        id: this.cart.id,
        salespersonId: salespersonId,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.showToast(
            this.$t("cart.salespersonChanged"),
            this.$t("cart.salespersonChangeTo", {
              value: this.cart.salespersonName,
            })
          );
          this.setOutletLoading = false;
        })
        .catch(() => {
          this.setOutletLoading = false;
        });
    },
    showUpdate(fields) {
      this.$refs.updateModal.show(this.cart, fields);
    },
    onUpdate(cart) {
      CartRepository.update({
        id: cart.id,
        expectDeliveryDate: cart.expectDeliveryDate,
        orderReference: cart.orderReference,
        note: cart.note,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.$refs.updateModal.hide();
          this.mapCart(data);
          this.showToast(
            this.$t("cart.cartUpdated"),
            this.$t("cart.cartInfoUpdated")
          );
        })
        .catch(() => {
          this.$refs.updateModal.stopLoading();
        });
    },
    addPromotionFunction(cartLine) {
      this.$refs.promotionFunctionModal.show(cartLine);
    },
    savePromotionFunction(cartLine, reason) {
      CartRepository.setPromotionFunction({
        cartLineId: cartLine.id,
        promotionFunctionNo: reason.no,
        promotionFunctionDescription: reason.reasonCodeDes,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
        })
        .catch(() => {
          this.products[index].loading = false;
        });
    },
    clearCart() {
      this.cartLoading = true;
      CartRepository.clear({
        id: this.cart.id,
      })
        .then((response) => {
          const data = response?.data?.data;
          this.mapCart(data);
          this.cartLoading = false;
          this.showToast(
            this.$t("cart.cartCleared"),
            this.$t("cart.allItemRemoved"),
            "light-danger",
            "danger"
          );
          this.isSubmit = false;
        })
        .catch(() => {
          this.cartLoading = false;
        });
    },
    proceed() {
      this.isSubmit = true;
      if (!this.cart.orderReference || !this.cart.salespersonId) {
        Swal.fire({
          title: this.$t("general.attention"),
          icon: "info",
          html: this.$t("general.fillTheRequiredField"),
          buttonsStyling: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        return;
      }

      this.$refs.confirmOrderModal.show(this.cart);
    },
    checkout() {
      this.isSubmit = true;
      if (!this.cart.orderReference || !this.cart.salespersonId) {
        Swal.fire({
          title: this.$t("general.attention"),
          icon: "info",
          html: this.$t("general.fillTheRequiredField"),
          buttonsStyling: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
        return;
      }

      this.checkoutLoading = true;
      CartRepository.checkout({
        id: this.cart.id,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (typeof data == "number") {
            this.cartLines.forEach((element) => {
              const index = this.products.findIndex((row) => {
                return row.id == element.productId;
              });
              if (index != -1) {
                this.products[index].balance =
                  element.balance - element.quantity;
              }
            });

            this.mapCart();
            Swal.fire({
              title: this.$t("cart.checkout"),
              icon: "success",
              html: this.$t("cart.checkoutDescription"),
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-success",
              },
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonText: this.$t("button.viewOrder"),
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({
                  name: "view-sales-order",
                  params: {
                    id: data,
                  },
                });
              }
            });
          } else {
            this.mapCart(data);
            Swal.fire({
              title: this.$t("general.attention"),
              icon: "info",
              html: this.$t("cart.outOfStock"),
              buttonsStyling: false,
              showConfirmButton: false,
              showCloseButton: true,
            });
          }
          this.checkoutLoading = false;
          this.$refs.confirmOrderModal.hide();
        })
        .catch(() => {
          this.checkoutLoading = false;
          this.$refs.confirmOrderModal.hide();
        });
    },
    mapCart(data = null) {
      if (!data) {
        this.cart = {};
        this.cartLines = [];
        this.cartKey++;

        this.$bus.$emit("cart-update", salesOrderType, 0);
        return;
      }
      this.cart = { ...data };
      if (this.cart.lines) {
        const lines = this.cart.lines.map((element) => {
          return {
            ...element,
            loading: false,
            isOutOfStock: false,
          };
        });
        this.cartLines = [...lines];

        const count = this.cartLines.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.quantity;
        }, 0);
        this.$bus.$emit("cart-update", salesOrderType, count);
      }
    },
    startProductLoading(id) {
      const index = this.products.findIndex((element) => {
        return element.id == id;
      });
      if (index != -1) {
        this.products[index].loading = true;
      }
    },
    stopProductLoading(id) {
      const index = this.products.findIndex((element) => {
        return element.id == id;
      });
      if (index != -1) {
        this.products[index].loading = false;
      }
    },
    startCartLineLoading(id) {
      const index = this.cartLines.findIndex((element) => {
        return element.id == id;
      });
      if (index != -1) {
        this.cartLines[index].loading = true;
      }
    },
    stopCartLineLoading(id) {
      const index = this.cartLines.findIndex((element) => {
        return element.id == id;
      });
      if (index != -1) {
        this.cartLines[index].loading = false;
      }
    },
    showToast(
      title,
      message,
      variant = "light-primary",
      textVariant = "primary"
    ) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        autoHide: true,
        autoHideDelay: 3000,
        props: {
          title: title,
          text: message,
          iconType: "line-awesome",
          icon: "las la-shopping-cart",
          variant: variant,
          textVariant: textVariant,
        },
      });
    },
  },
  setup() {
    const resource = "agent-sales-order";
    const route = "sales-order";

    return {
      resource,
      route,
    };
  },
};
</script>
./form/expectDeliveryDateInput./form/orderReferenceInput
