var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',[_c('b-skeleton',{attrs:{"width":"100%"}}),_c('hr',{staticClass:"my-2"}),_c('b-skeleton',{attrs:{"width":"30%"}}),_c('b-skeleton',{attrs:{"width":"70%"}}),_c('b-skeleton',{attrs:{"width":"60%"}}),_c('b-skeleton',{attrs:{"width":"70%"}}),_c('b-skeleton',{attrs:{"width":"40%"}})],1)]},proxy:true}])},[_c('b-card',{staticClass:"p-1 mb-0 order-info",attrs:{"no-body":""}},[(_vm.cart.outletId)?_c('div',[_c('outlet-info',{attrs:{"cart":_vm.cart},on:{"showOutletModal":_vm.showOutletModal,"changeAddress":_vm.changeAddress}}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{class:{
              'text-danger': _vm.isSubmit && !_vm.cart.salespersonId,
            },attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.salesperson"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(_vm.cart.isAllowToChangeSalesperson)?_c('b-link',{staticClass:"link-underline",class:{
                'text-secondary': !_vm.cart.salespersonId,
                'text-danger': _vm.isSubmit && !_vm.cart.salespersonId,
              },on:{"click":_vm.showSalespersonModal}},[(_vm.cart.salespersonName)?_c('span',[_vm._v(" "+_vm._s(_vm.cart.salespersonName)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("button.set"))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.cart.salespersonName ? _vm.cart.salespersonName : _vm.$t("field.noSalesperson"))+" ")])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{class:{
              'text-danger': _vm.isSubmit && !_vm.cart.expectDeliveryDate,
            },attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.expectDeliveryDate"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('b-link',{staticClass:"link-underline",class:{
                'text-secondary': !_vm.cart.expectDeliveryDate,
                'text-danger': _vm.isSubmit && !_vm.cart.expectDeliveryDate,
              },on:{"click":function($event){return _vm.$emit('updateExpectedDeliveryDate')}}},[(_vm.cart.expectDeliveryDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.cart.expectDeliveryDate,"LL"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("button.set"))+" ")])])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{class:{
              'text-danger': _vm.isSubmit && !_vm.cart.orderReference,
            },attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.orderReference"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('b-link',{staticClass:"link-underline",class:{
                'text-secondary': !_vm.cart.orderReference,
                'text-danger': _vm.isSubmit && !_vm.cart.orderReference,
              },attrs:{"disabled":_vm.isDisableUpdate},on:{"click":function($event){return _vm.$emit('updateOrderReference')}}},[_vm._v(" "+_vm._s(_vm.cart.orderReference ? _vm.cart.orderReference : _vm.$t("button.set"))+" ")])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t("field.remark"))+" ")]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('b-link',{staticClass:"link-underline",class:{
                'text-secondary': !_vm.cart.note,
              },on:{"click":function($event){return _vm.$emit('updateRemark')}}},[_vm._v(" "+_vm._s(_vm.cart.note ? _vm.cart.note : _vm.$t("button.set"))+" ")])],1)],1)],1):_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.showOutletModal}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}})],1)],1),_c('n-async-single-select',{ref:"outlet",attrs:{"name":"outlet","placeholder":_vm.$t('general.pleaseChooseOutlet'),"repository":"outlet","selection-key":"id","selection-label":"label","localization":true,"disabled":_vm.setOutletLoading},on:{"input":_vm.onOutletChange},model:{value:(_vm.outletId),callback:function ($$v) {_vm.outletId=$$v},expression:"outletId"}})],1),_c('hr',{staticClass:"my-1"}),_c('h4',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" "+_vm._s(_vm.$t("field.cart"))+" ")]),(_vm.cartLines.length)?_c('b-col',{staticClass:"text-right"},[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.sumTotalQuantity())+" ")])],1):_vm._e()],1)],1),(_vm.cartLines.length)?_c('div',{staticClass:"cart-item-responsive"},_vm._l((_vm.cartLines),function(line,idx){return _c('div',{key:("line-" + (line.id))},[_c('cart-item-line',{class:{
              'out-of-stock': line.quantity > line.balance,
            },attrs:{"index":idx,"item":line,"cart":_vm.cart},on:{"delete":_vm.removeItem,"change":_vm.onChangeQuantity,"addPromotionFunction":_vm.addPromotionFunction}}),(idx < _vm.cartLines.length - 1)?_c('hr'):_vm._e()],1)}),0):_c('div',{staticClass:"text-center"},[_c('b-img',{staticClass:"w-50 empty-cart-img",attrs:{"fluid":"","src":require('@/assets/images/icons/empty-cart.svg')}}),_c('h6',[_vm._v(" "+_vm._s(_vm.$t("cart.cartEmpty"))+" ")]),_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.$t("cart.startAddProduct"))+" ")])],1),(_vm.cart.id)?_c('div',[_c('hr',{staticClass:"my-1"}),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('n-button-loading',{staticClass:"btn-cart",attrs:{"variant":"outline-danger","block":"","loading":_vm.checkoutLoading},on:{"click":function($event){return _vm.$emit('clear')}}},[_c('span',[_vm._v(_vm._s(_vm.$t("button.clearCart")))])])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('n-button-loading',{staticClass:"btn-cart",attrs:{"variant":"primary","block":"","loading":_vm.checkoutLoading,"disabled":!_vm.cartLines.length || !_vm.cart.outletId},on:{"click":function($event){return _vm.$emit('proceed')}}},[_c('span',[_vm._v(_vm._s(_vm.$t("button.proceed")))])])],1)],1)],1):_vm._e()],1)],1),_c('outlet-modal',{ref:"outletModal",on:{"save":_vm.onOutletChange}}),_c('address-modal',{ref:"addressModal",on:{"save":_vm.onAddressChange}}),_c('salesperson-modal',{ref:"salespersonModal",on:{"save":_vm.onSalespsersonChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }